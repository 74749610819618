<template>
  <div style="margin-bottom: var(--van-tabbar-height)">

    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>

  <van-tabbar route v-model="active">
    <!--  <van-tabbar route v-model="active" safe-area-inset-bottom>-->
    <!-- <van-tabbar-item replace :to="{ name: 'home' }" icon="home-o">首页</van-tabbar-item> -->
    <!-- <van-tabbar-item style="font-size: 2.66667vw" replace to="/receiveScan" icon="description">收货扫描</van-tabbar-item> -->
    <van-tabbar-item replace to="/receiveScan" icon="description">资产盘点</van-tabbar-item>
    <!-- <van-tabbar-item style="font-size: 2.66667vw" replace to="/scanPick" icon="sign">扫描捡配
    </van-tabbar-item>
    <van-tabbar-item style="font-size: 2.66667vw" replace to="/pdaRummageNew" icon="bag-o">PDA翻包</van-tabbar-item>
    <van-tabbar-item style="font-size: 2.66667vw" replace to="/loadcarorderNew" icon="logistics">扫描发货</van-tabbar-item>
    <van-tabbar-item style="font-size: 2.66667vw" replace to="/scanPackageCode" icon="shop-o">箱号扫描</van-tabbar-item> -->
    <van-tabbar-item replace to="/my" icon="contact">我的</van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
import { ref } from "vue";
import _ from "lodash";
export default {
  name: "Main",
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  data() {
    return {
      toConfirmNumber: "0",
    };
  },
  methods: {
    getNotFillOrderNumber() {
      var option = {
        portName: 'tms.TmsDispatchOrderRfqCarrier.getTmsDispatchOrderRfqCarriers',
        data: { 'vapp': 'vapp' },
        needLoading: false,
        successCallback: (data) => {
          this.toConfirmNumber = data.data.toConfirmNumber
          // console.log(data.data.toConfirmNumber)
          // this.$sapi.storage.setItemJson('toConfirmNumber', data.data.toConfirmNumber)
          // if (!_.isEmpty(entities)) {
          //   var entity = entities[0];
          //   this.$sapi.storage.setItemJson('driver', entity)
          //   this.$sapi.storage.setItemJson('visitor', entity)
          // } else {
          //   this.$dialog.alert({
          //     title: '',
          //     message: '您尚未维护个人身份信息！',
          //   }).then(() => {
          //     this.$router.push('visitorInfo');
          //   });
          // }
        }
      }
      this.$sapi.callPort(option);
    },
  },
  setup() {
    const active = ref(0);
    return { active };
  },
  created() {
    // this.getNotFillOrderNumber();
  },
};


</script>
<style>
.item {
  display: flex;
  border-radius: 5px;
  flex-direction: row;
  box-shadow: 0 0 2px #ddd;
  align-items: center;
  padding: 10px;
  margin: 2px;
}

.title {
  display: block;
  text-decoration: none;
  line-height: 1.5;
  margin: 0px 0px;
  background-image: -webkit-linear-gradient(left,
      #4491e8,
      #b6549a,
      #b6549a);
  color: transparent;
  -webkit-background-clip: text;
  background-size: 200% 100%;
  animation: slide 5s infinite linear;
  font-size: 16px;
}

.test1 {
  left: 44%;
  top: 10%;
  text-align: center;
  font-size: 16px;
  position: absolute;
  width: 16px;
  color: white;
  height: 16px;
  background: red;
  border-radius: 10px;
}

.text {
  display: inline-block;
  color: #1d6ab6;
  font-size: 10px;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
}

.item>.content {
  width: 500px;

}
</style>
